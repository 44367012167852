import React, { useReducer } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRefresh } from '@fortawesome/free-solid-svg-icons';

import GetHeartbeats from './Admin/GetHeartbeats';
import GetLotsWithMostRevisions from './Admin/GetLotsWithMostRevisions';
import GetRecentSpecFiles from './Admin/GetRecentSpecFiles';
import GetSiteAlert from './Admin/GetSiteAlert';
import GetQirs from './Admin/GetQir';
import GetSample from './Admin/GetSample';
import SampleManagerApiHealthCheck from './Admin/SampleManagerApiHealthCheck';

const Admin = () => {
    const { t } = useTranslation();
    const intialCards = [];
    const [cards, dispatch] = useReducer(
        cardReducer,
        intialCards,
        intialCardsFunc
    );

    function cardReducer (cards, action) {
        switch (action.type) {
            case "setOnRefresh":
            case "setVariant":
                return [...cards.map(card => card.title === action.card.title ? action.card : card)];
            default:
                throw Error("Action not recognised");
        }
    }

    function setRefreshFunction (func, title) {
        let card = cards.find(card => card.title === title)
        card.onRefresh = func;
        dispatch({
            type: "setOnRefresh",
            card: card,
        });
    }

    function setVariant(variant, title) {
        let card = cards.find(card => card.title === title)
        card.variant = variant;
        dispatch({
            type: "setVariant",
            card: card,
        });
    }

    function intialCardsFunc(intialCards) {
        if (intialCards.length <= 0) {
            const adminTitle = "Admin.Heartbeat.Title";
            const recentSpecTitle = "Admin.RecentSpecFiles.Title";
            const lotsWithRevisionsTitle = "Admin.LotsWithMostRevisions.Title";
            const qirsTitle = "Admin.Qirs.Title";
            const samplesTitle = "Admin.Samples.Title";
            const smApiTitle = "Admin.SampleManagerApiChecks.Title";
            const siteAlertTitle = "Admin.SiteAlert.Title";
            return [
                { title: adminTitle, description: t("Admin.Heartbeat.Description"), component: <GetHeartbeats setVariant={(variant) => setVariant(variant, adminTitle)} setRefreshFunc={(func) => setRefreshFunction(func, adminTitle)} /> },
                { title: recentSpecTitle, description: t("Admin.RecentSpecFiles.Description"), component: <GetRecentSpecFiles setVariant={(variant) => setVariant(variant, recentSpecTitle)} setRefreshFunc={(func) => setRefreshFunction(func, recentSpecTitle)} /> },
                { title: lotsWithRevisionsTitle, description: t("Admin.LotsWithMostRevisions.Description"), component: <GetLotsWithMostRevisions setVariant={(variant) => setVariant(variant, lotsWithRevisionsTitle)} setRefreshFunc={(func) => setRefreshFunction(func, lotsWithRevisionsTitle)} /> },
                { title: qirsTitle, description: t("Admin.Qirs.Description"), component: <GetQirs setVariant={(variant) => setVariant(variant, qirsTitle)} /> }, 
                { title: samplesTitle, description: t("Admin.Samples.Description"), component: <GetSample setVariant={(variant) => setVariant(variant, samplesTitle) } /> },
                { title: smApiTitle, description: t("Admin.SampleManagerApiChecks.Description"), component: <SampleManagerApiHealthCheck setVariant={(variant) => setVariant(variant, smApiTitle)} /> },
                { title: siteAlertTitle, description: t("Admin.SiteAlert.Description"), component: <GetSiteAlert setVariant={(variant) => setVariant(variant, siteAlertTitle)} /> },
            ];
        }
        return intialCards;
    }

    function getVariantClasses(variant) {
        switch (variant) {
            case "danger": return "bg-danger text-white";
            case "warning": return "bg-warning text-black";
            default : return null;
        }
    }

    return (
        <Container>
            <Row xs={1} md={2} lg={3} xl={4} >
                {cards.map((card, index) =>
                    <Col className="pt-2" key={`card-${index}`}>
                        <Card className="h-100">
                            <Card.Header as="h5" className={getVariantClasses(card.variant) ?? "bg-white"}>
                                <span className="d-flex">
                                    {t(card.title)}
                                    {card.onRefresh && 
                                        <Button variant="link" className={`${getVariantClasses(card.variant) ?? "text-black"} ms-auto`} onClick={card.onRefresh}>
                                            <FontAwesomeIcon icon={faRefresh}/>
                                        </Button>
                                    }
                                </span>
                            </Card.Header>
                            <Card.Body className="d-flex flex-column">
                                <Card.Subtitle>
                                    {card.description }
                                </Card.Subtitle>
                                <div className="mt-2 flex-fill d-flex flex-column">
                                    {card.component}
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>)}
            </Row>
        </Container>
    )
}

export default Admin