import { useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';

import { fetchDataAuthenticated } from '../../Utilities/HttpUtils'
import useAlert from '../../Alerts/useAlert';
import DateAsLocaleString from '../../Utilities/DateAsLocaleString';

const GetSample = ({ setVariant }) => {
    const { setAlert } = useAlert();
    const [samples, setSample] = useState();
    const [sampleId, setSampleId] = useState("");
    const [error, setError] = useState();
 
    const getSample = () => {
        fetchDataAuthenticated(`api/v1/admin/sample/${sampleId}`, "GET")
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => Promise.reject(error));                  
                }
                return response.json();
            })
            .then(data => {
                setVariant("");
                setSample(data);
                setError(null);
            }).catch(error => {
                setVariant("danger");
                setAlert("danger", error.title, error.detail)
                setSample();
                setError(error.detail);
            });
    }

    return (
        <>
            {samples &&
                <>
                    <Row>
                        <Col>
                            Sample Id:
                        </Col>
                        <Col>
                            {samples[0].sampleId}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Sampled Date:
                        </Col>
                        <Col>
                            <DateAsLocaleString date={samples[0].sampledDate} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Tests in Sample:
                        </Col>
                        <Col>
                            {samples[0].tests.length}
                        </Col>
                    </Row>                                                                  
                </>
            }
            <Row className="mt-1">
                <Form>
                    <Form.Group>
                        <Form.Label><strong>Sample ID</strong></Form.Label>
                        <Form.Control type="text" value={sampleId} onChange={(e)=>setSampleId(e.target.value)} />
                    </Form.Group>
                </Form>
            </Row>
            {error &&
                <div>
                    {error}
                </div>}
            <Button type="submit" className="ms-auto mt-auto" onClick={getSample}>Get</Button>
        </>
    )
}

export default GetSample;