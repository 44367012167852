import { useState, useEffect } from 'react';
import { fetchDataAuthenticated } from './HttpUtils';

const baseUrl = "api/v1";

export function useBusinessUnits(includeDeleted = false) {
    const [businessUnits, setBusinessUnits] = useState([]);

    useEffect(() => {
        const getBusinessUnits = async function () {
            let url = `api/v1/businessUnit/?includeDeleted=${includeDeleted}`;
            let errorMessagePrefix = "Failed to get buisiness units: ";
            await fetchHelper(url, setBusinessUnits, errorMessagePrefix);
        }

        getBusinessUnits();
    }, [includeDeleted]);

    return businessUnits;
}

export function useEquipmentTypes(includeDeleted, businessUnitId) {
    const [equipmentTypes, setEquipmentTypes] = useState([]);

    useEffect(() => {
        const getEquipmentTypes = async function () {
            if (businessUnitId > 0) {
                let url = `${baseUrl}/equipmentType/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`;
                let errorMessagePrefix = "Failed to get equipment types: ";
                await fetchHelper(url, setEquipmentTypes, errorMessagePrefix);
            }
            else {
                setEquipmentTypes([]);
            }
        }

        getEquipmentTypes();

    }, [businessUnitId, includeDeleted]);

    return equipmentTypes;
}

export function useLines(includeDeleted, businessUnitId) {
    const [lines, setLines] = useState([]);

    useEffect(() => {
        const getLines = async function () {
            if (businessUnitId > 0) {
                let url = `api/v1/line/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`;
                let errorMessagePrefix = "Failed to get lines: ";
                await fetchHelper(url, setLines, errorMessagePrefix);
            }
            else {
                setLines([]);
            }
        }

        getLines();

    }, [businessUnitId, includeDeleted]);

    return lines;
}

export function useLinesMultipleBusinessUnit(businessUnitIds) {
    const [lines, setLines] = useState([]);

    useEffect(() => {
        const getLinesByBusinessUnit = async function () {
            let url = `api/v1/line/multiplebusinessunit?businessUnitIds=${businessUnitIds}`
            let errorMessagePrefix = "Failed to get lines by business units: "
            await fetchHelper(url, setLines, errorMessagePrefix)
        }

        getLinesByBusinessUnit()

    }, [businessUnitIds]);

    return lines;
}

export function useQualities(includeDeleted, businessUnitId) {
    const [qualities, setQualities] = useState([]);

    useEffect(() => {
        const getQualities = async function () {
            if (businessUnitId > 0) {
                let url = `${baseUrl}/quality/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`;
                let errorMessagePrefix = "Failed to get qualities: ";
                await fetchHelper(url, setQualities, errorMessagePrefix);
            }
            else {
                setQualities([]);
            }
        }

        getQualities();

    }, [businessUnitId, includeDeleted]);

    return qualities;
}

export function useEquipment(includeDeleted, businessUnitId) {
    const [equipment, setEquipment] = useState([]);

    useEffect(() => {
        const getEquipment = async function () {
            if (businessUnitId > 0) {
                let url = `${baseUrl}/equipment/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`;
                let errorMessagePrefix = "Failed to get equipment: ";
                await fetchHelper(url, setEquipment, errorMessagePrefix);
            }
            else {
                setEquipment([]);
            }
        }

        getEquipment();

    }, [businessUnitId, includeDeleted]);

    return equipment;
}

export function useRecipe(name, businessUnit) {

    const [recipe, setRecipe] = useState();

    useEffect(() => {
        const getRecipe = async function () {
            let url = `api/v1/recipe/nameAndBusinessUnit?name=${name}&businessUnitCode=${businessUnit}`;
            let errorMessagePrefix = "Failed to get recipe: ";
            await fetchHelper(url, setRecipe, errorMessagePrefix);
        };

        if (name !== undefined && businessUnit !== undefined) {
            getRecipe();
        }
    }, [name, businessUnit]);

    

    return { recipe, setRecipe };
}

export function useRecipeTypes(includeDeleted, businessUnitId) {
    const [recipeTypes, setRecipeTypes] = useState([]);

    useEffect(() => {
        const getRecipeTypes = async function () {
            if (businessUnitId > 0) {
                let url = `${baseUrl}/recipetype/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`;
                let errorMessagePrefix = "Failed to get recipe types: ";
                await fetchHelper(url, setRecipeTypes, errorMessagePrefix);
            }
            else {
                setRecipeTypes([]);
            }
        }

        getRecipeTypes();

    }, [businessUnitId, includeDeleted]);

    return recipeTypes;
}

export function useRecipeProperties(includeDeleted, businessUnitId) {
    const [recipeProperties, setRecipeProperties] = useState([]);

    useEffect(() => {
        const getRecipeProperties = async function () {
            if (businessUnitId > 0) {
                let url = `${baseUrl}/recipeproperty/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`;
                let errorMessagePrefix = "Failed to get recipe types: ";
                await fetchHelper(url, setRecipeProperties, errorMessagePrefix);
            }
            else {
                setRecipeProperties([]);
            }
        }

        getRecipeProperties();

    }, [businessUnitId, includeDeleted]);

    return recipeProperties;
}

export function useRecipeDataTypes(includeDeleted, businessUnitId) {
    const [recipeDataTypes, setRecipeDataTypes] = useState([]);

    useEffect(() => {
        const getRecipeDataTypes = async function () {
            if (businessUnitId > 0) {
                let url = `${baseUrl}/recipedatatype/?includeDeleted=${includeDeleted}&businessUnitId=${businessUnitId}`;
                let errorMessagePrefix = "Failed to get recipe data types: ";
                await fetchHelper(url, setRecipeDataTypes, errorMessagePrefix);
            }
            else {
                setRecipeDataTypes([]);
            }
        }

        getRecipeDataTypes();

    }, [businessUnitId, includeDeleted]);

    return recipeDataTypes;
}

export function useCalcFunctions() {
    const [calcFunctions, setCalcFunctions] = useState([]);

    useEffect(() => {
        const getCalcFunctions = async function () {
            let url = `${baseUrl}/qualityfunctions/GetQualityCalculateFunctions`;
            let errorMessagePrefix = "Failed to get calc functions: "
            await fetchHelper(url, setCalcFunctions, errorMessagePrefix);
        }

        getCalcFunctions();

    }, []);

    return calcFunctions;
}

export function useLabFunctions() {
    const [labFunctions, setLabFunctions] = useState([]);

    useEffect(() => {
        const getLabFunctions = async function () {
            let url = `${baseUrl}/qualityfunctions/GetLabCalculateFunctions`;
            let errorMessagePrefix = "Failed to get lab functions: ";
            await fetchHelper(url, setLabFunctions, errorMessagePrefix)
        }

        getLabFunctions();

    }, []);

    return labFunctions;
}

export function useOffspecFunctions() {
    const [offspecFunctions, setOffspecFunctions] = useState([]);

    useEffect(() => {
        const getOffspecFunctions = async function () {
            let url = `${baseUrl}/qualityfunctions/GetQualityOffspecFunctions`;
            let errorMessagePrefix = "Failed to get offspec functions: ";
            await fetchHelper(url, setOffspecFunctions, errorMessagePrefix)
        }

        getOffspecFunctions();

    }, []);

    return offspecFunctions;
}

export function useGetSplittableBatches(lotNumber) {
    const [loading, setLoading] = useState(true);
    const [splittableBatches, setSplittableBatches] = useState([]);

    useEffect(() => {
        setLoading(true);
        getSplittableBatches(lotNumber);
    }, [lotNumber]);

    async function getSplittableBatches(lotNumber) {
        let url = `${baseUrl}/lot/${lotNumber}/splittablebatches`;
        let errorMessagePrefix = `Failed to get splittable batches for lot '${lotNumber}': `;
        await fetchHelper(url, setSplittableBatches, errorMessagePrefix, setLoading)
    }

    return { splittableBatches, loading };
}

export function useGetSplittableSilos(lotNumber) {
    const [loading, setLoading] = useState(true);
    const [splittableSilos, setSplittableSilos] = useState([]);

    useEffect(() => {
        setLoading(true);
        getSplittableSilos(lotNumber);
    }, [lotNumber]);

    async function getSplittableSilos(lotNumber) {
        let url = `${baseUrl}/lot/${lotNumber}/splittablesilos`;
        let errorMessagePrefix = `Failed to get splittable silos for lot '${lotNumber}': `;
        await fetchHelper(url, setSplittableSilos, errorMessagePrefix, setLoading)
    }

    return { splittableSilos, loading };
}

export function useLotStatuses() {
    const [loading, setLoading] = useState(true);
    const [lotStatuses, setLotStatuses] = useState([]);

    useEffect(() => {
        getLotStatuses();
    }, []);

    async function getLotStatuses() {
        let url = `${baseUrl}/lotStatus`;
        let errorMessagePrefix = `Failed to get lotStatuses': `;
        await fetchHelper(url, setLotStatuses, errorMessagePrefix, setLoading)
    }

    return { lotStatuses, loading };
}

const fetchHelper = async (url, setData, errorMessagePrefix, setLoading=undefined) => {
    await fetchDataAuthenticated(url)
        .then(async response => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(await response.json());
        })
        .then(data => {
            setData(data);
        })
        .catch(error => {
            console.error(errorMessagePrefix + error.message);
        })
        .finally(() => {
            setLoading && setLoading(false);
        });
}