import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { fetchDataAuthenticated } from '../../Utilities/HttpUtils'
import useAlert from '../../Alerts/useAlert';

const SampleManagerApiHealthCheck = ({ setVariant }) => {
    const { setAlert } = useAlert();
    const [healthCheck, setHealthCheck] = useState();
    const [ping, setPing] = useState();

    const getHealthCheck = () => {
        fetchDataAuthenticated("api/v1/admin/sampleManagerApi/health", "GET")
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => Promise.reject(error));
                }
                setVariant("");
                let healthCheckResponse = "Succesful Health Check";
                setAlert("success", "Succesful", healthCheckResponse);
                setHealthCheck(healthCheckResponse);
            })
            .catch(error => {
                setAlert("danger", error.title, error.detail);
                setHealthCheck(error.detail);
                setVariant("danger");
            });
    }

    const getPing = () => {
        fetchDataAuthenticated("api/v1/admin/sampleManagerApi/ping", "GET")
            .then(response => {
                if (response.ok) {
                    let pingResponse = "Succesfully pinged SM-API";
                    setAlert("success", "Succesful", pingResponse);
                    setPing(pingResponse)
                } else {
                    response.json().then(error => {
                        setAlert("danger", error.title, error.detail);
                        setPing(error.detail);
                        setVariant("danger");
                    })
                }
            });
    }

    return (
        <>
            <Button className="mt-2" onClick={getHealthCheck}>Health Check</Button>
            {healthCheck &&
                <div className="mt-1 mb-2">
                    {healthCheck}
                </div>}
            <Button className="mt-2" onClick={getPing}>Ping</Button>
            {ping &&
                <div className="mt-1">
                    {ping}
                </div>}
        </>
    )
}

export default SampleManagerApiHealthCheck;